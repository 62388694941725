import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Title from '../components/UI/Title'
import Label from '../components/UI/Label'
import Confirm from '../components/UI/Confirm'
import moment from 'moment'
import 'moment/locale/fr'
import { language, slugify } from '../utils'
import { FiCalendar, FiCheck, FiClock, FiCreditCard, FiEdit, FiExternalLink, FiGift, FiRefreshCw, FiShare2, FiTrash2 } from 'react-icons/fi'
import Button from '../components/UI/Button'
import StripeCard from '../components/StripeCard'
import { findDealer, updateCard, removeCards, createProEvent, chargeInvoice, settleInvoice, updateEvent, getProducts, updateDealer, cancelEvent, refreshInvoice, closeInvoice } from '../api'
import Loader from '../components/UI/Loader'
import Price from '../components/UI/Price'
import Popup from '../components/UI/Popup'
import { AddressModal, PickupModal } from '../components/Delivery'
import Delivery from '../components/Delivery'
import DatePicker from '../components/DatePicker'
import GoogleMapReact from 'google-map-react'
import { useStaticQuery } from 'gatsby'
import Footer from '../components/UI/Footer'
import Headers from '../components/UI/Headers'
moment.locale(language())

export function LogisticCondensed({ event }) {
  if (!event.stripeCache) return null
  const products = event.defaultProducts || event.products || []

  const selected = products.filter(p => p.shipping).sort((a, b) => b.stripeCache.unit_amount - a.stripeCache.unit_amount)[0]
  const pickup = event.pickup ? `${event.pickup.name}` : 'non défini'
  const address = event.address ? `${event.address.first_name} ${event.address.phone}, ${event.address.address1} ${event.address.city}` : 'non défini'
  return (selected?.upsale) ? <><label>Livraison</label> {address}</> : <><label>Retrait dans nos bureaux de</label> {pickup}</>
}
  

const location = () => (typeof window !== `undefined`) ? window.location : { pathname: '' }
const match = (location().pathname.match(/pro\/(\d+)_(.+)/) || [])
const dealerId = match[1]
const token = match[2]

export function Share({ event }) {
  const [open, setOpen] = useState(false)
  const pola = event.customization && event.customization[0]
  return (
    <>
      <Button Icon={FiShare2} variant="secondary" className="m-1" onClick={() => setOpen(true)}>Partager</Button>
      {open &&
        <Popup close={() => setOpen(false)} title={`Événement du ${moment(event.date).format('DD/MM/YYYY')}`}>
          <Label title="Lien de création du cadre photo">
            <input onClick={e => e.target.select()} className="form-input w-full" type="text" value={`https://crea.wizito.com/project/${pola?.id}_${pola?.token}`} />
          </Label>
          <Label title="Lien de l'espace personnel">
            <input onClick={e => e.target.select()} className="form-input w-full" type="text" value={`https://party.wizito.com/event/${event.id}_${event.token}`} />
          </Label>
        </Popup>
      }
    </>
  )
}

function Event({ event, confirmEvent, payAll, move, cancel, shippings }) {
  const invoices = event.stripeCache ? event.stripeCache.invoices.filter(i => parseInt(i.metadata.eventId) === event.id) : []
  if (event.stripeCache && invoices.length === 0) return null
  const draft = !invoices.find(i => ['open', 'paid'].includes(i.status))
  const total = invoices.reduce((acc, curr) => acc + curr.total, 0)
  const prePayment = invoices[0]?.pre_payment_credit_notes_amount
  const remaining = invoices[0]?.status !== 'paid' ? invoices[0]?.amount_remaining : 0
  const blackFriday = event.origin === 'BF-2021-PRO'
  const daysDifference = Math.floor(moment(new Date(event.date)).diff(moment(), 'd', true)) + 1
  const locked = daysDifference < 7
  return (
    <div className={`relative flex justify-between items-center bg-gray-100 rounded text-black py-4 px-6 mt-4 pl-12 box-border w-full ${blackFriday && 'border-4 border-gray-800'}`}>
      {event.stripeCache === null &&
        <div className="absolute top-0 left-0 flex justify-center items-center text-gray-600 w-full h-full">
          <Loader />
        </div>
      }
      <div
        className={`absolute flex justify-between items-center left-0 top-0 h-full text-white text-sm px-1 py-1 rounded-l ${['paid', 'partially-paid', 'free'].includes(event.status) ? 'bg-pink' : 'bg-gray-800'}`}>
        <div className="font-bold flex items-center" style={{ writingMode: 'vertical-lr', transform: 'rotate(-180deg)' }}>
          {['paid', 'partially-paid', 'free'].includes(event.status) ? 'réservé' : 'non réservé'}
        </div>
      </div>
      <div className="flex flex-col items-start">
        <div className="flex font-medium">
          {prePayment > 0 &&
            <div className="border-pink border-2 text-pink bg-white text-sm px-2 py-1 rounded mb-1 mr-2">
              <FiCheck className="inline-block text-base mr-1" />Acompte&nbsp;<Price price={prePayment} />
            </div>
          }
          {remaining > 0 &&
            <div className="border-black border-2 text-black bg-white text-sm px-2 py-1 rounded mb-1 mr-2">
              <FiClock className="inline-block text-base mr-1" />Reste à payer&nbsp;<Price price={remaining} />
            </div>
          }
          {remaining === 0 &&
            <div className="border-pink border-2 text-pink bg-white text-sm px-2 py-1 rounded mb-1 mr-2">
              <FiCheck className="inline-block text-base mr-1" />Payé intégralement&nbsp;<Price price={total} />
            </div>
          }
          {event.stripeCache === false &&
            <div className="border-pink border-2 text-pink bg-white text-sm px-2 py-1 rounded mb-1 mr-2">
              <FiCheck className="inline-block text-base mr-1" />Validé
            </div>
          }
        </div>
        <a href={`https://party.wizito.com/event/${event.id}_${event.token}`}>
          <div className="flex items-center">
            <div className="text-xl flex items-center font-black">
              {event.date ? moment(event.date).format('DD/MM/YYYY') : 'Date à définir'}
            </div>
            <div className="text-xl ml-2 font-thin">{event.label}</div>
          </div>
        </a>
        <div className=" text-sm"><LogisticCondensed event={event} shippings={shippings} /></div>
      </div>
      <div className="flex flex-col items-end">
        <div className="flex">
          <Share event={event} />
          <Button Icon={FiEdit} variant="secondary" className="m-1" onClick={move}>Modifier</Button>
          {draft &&
            <Button Icon={FiTrash2} variant="secondary" className="m-1" onClick={cancel}>Annuler</Button>
          }
        </div>
        <div className="flex">
          {(event.stripeCache && (remaining > 0 && prePayment === 0 && invoices.length === 1) && !locked) &&
            <Button Icon={FiCreditCard} variant="default" className="m-1" onClick={confirmEvent} disabled={!event.stripeCache}>Payer un acompte&nbsp;<Price price={15000} /></Button>
          }
          {(event.stripeCache && remaining > 0) &&
            <Button Icon={FiCreditCard} variant="default" className="m-1" onClick={() => payAll(remaining)} disabled={!event.stripeCache || (locked && !prePayment)}>Payer le solde&nbsp;<Price price={remaining} /></Button>
          }
        </div>
      </div>
    </div>
  )
}

export default function Pro() {
  const { stripePublic } = useStaticQuery(graphql`
    {
      strapi {
        locales {
          stripePublic
          language
        }
      }
    }
  `).strapi.locales.find(l => l.language === language())
  const [stripePromise, setStripePromise] = useState()
  const [datePicker, setDatePicker] = useState()
  const [confirmPopup, setConfirmPopup] = useState()
  const [dealer, setDealer] = useState(null)
  const [filter, setFilter] = useState('futur')
  const [products, setProducts] = useState([])
  const [currentPayment, setCurrentPayment] = useState()
  const refresh = () => findDealer(dealerId, token).then(d => { setDealer(d); return d })
  const shippings = products && products.filter(p => p.shipping)

  useEffect(() => {
    setStripePromise(loadStripe(stripePublic))
  }, [])

  useEffect(() => { if (!dealer || dealer.events.find(e => !e.stripeCache)) refresh() }, [dealer])
  useEffect(() => { getProducts().then(p => setProducts(p)) }, [])
  useEffect(() => {
    if (!dealer) return
    const intents = dealer.events.filter(e => e.status !== 'canceled').reduce((acc, curr) => [...acc, ...((curr.stripeCache || {}).intents || [])], [])
    console.log(intents)
    setCurrentPayment(intents.find(i => ['requires_action'].includes(i.status)))
  }, [dealer])
  if (dealer === null) return <Loader />

  const saveCard = card =>
    updateCard(dealer, card).then(setDealer)
  const deleteCards = card =>
    removeCards(dealer, card).then(setDealer)
  const newEvent = async () => {
    const { date, label } = await datePicker({})
    if (date)
      createProEvent(dealer, { date, label }).then(setDealer)
  }
  const confirmEvent = async event => {
    await confirmPopup(`Confirmez l'acompte de 150 €`)
    return chargeInvoice(event, 15000).then(refresh).then(({ events }) => {
      console.log('events', events)
      const intent = events.find(e => event.id === e.id).stripeCache.intents.find(i => ['requires_payment_method'].includes(i.status))
      if (intent) setCurrentPayment(intent)
    })
  }
  const onPaymentConfirmed = async ({ id }) => {
    const event = dealer.events.find(e => ((e.stripeCache || {}).intents || []).find(i => i.id === id))
    console.log(event, id)
    const intent = ((event.stripeCache || {}).intents || []).find(i => i.id === id)
    if (intent.invoice)
      return refreshInvoice(event).then(refresh)
    return chargeInvoice(event, 15000).then(refresh)
  }
  const payAll = async (event, remaining) => {
    await confirmPopup(<div>Payer le solde de <Price price={remaining} /></div>)
    const card = (dealer.stripeCache.paymentMethods.data[0] || {}).card
    if (card)
      return settleInvoice(event).then(refresh)
    else {
      const { stripeCache } = await closeInvoice(event)
      await refresh()
      setCurrentPayment(stripeCache.intents.find(i => ['requires_payment_method'].includes(i.status)))
    }
  }
  const switchProducts = async (p1, p2) => {
    return setDealer(await updateDealer(dealer, { defaultProducts: [...dealer.defaultProducts.filter(p => p.id !== p1?.id).map(p => p.id), p2.id] }))
  }
  const move = async event => {
    const { date, label } = await datePicker(event)
    console.log(date, label)
    if (date || label)
      updateEvent(event, { date, label }).then(refresh)
  }
  const update = data =>
    updateDealer(dealer, data).then(setDealer)
  const cancel = async event => {
    await confirmPopup(`Confirmez l'annulation`)
    return cancelEvent(event).then(refresh)
  }

  return (
    <>
      <DatePicker handler={setDatePicker} allowLabel />
      <GoogleMapReact bootstrapURLKeys={{ key: 'AIzaSyAVnzQlyDf5j9bbHjSqrXK4h8zTuFun22Q&libraries=places' }} />
      <Confirm handler={setConfirmPopup} />
      <Headers />
      <div className="bg-pink w-full py-5">
        <img src="/logo-blanc.png" alt="Logo" className="w-40 lg:w-40 mx-auto" />
      </div>
      <div className="flex flex-col justify-center items-center w-full mx-auto max-w-screen-lg py-8 lg:py-12 px-2 lg:px-0">
        <Title variant="extra-large" className="text-center">Welcome {dealer.firstname} !<br/></Title>
        <div className="text-3xl mb-10 text-center">Votre tableau de bord partenaire</div>
        <Button className="my-12" Icon={FiExternalLink} href="https://party.wizito.com/plaquette.pdf">Découvrir l'offre</Button>
        <div className="flex justify-between w-full mb-6">
          <Elements stripe={stripePromise} options={{ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito:wght@100;300;400;500' }] }} >
            <StripeCard dealer={dealer} saveCard={saveCard} deleteCards={deleteCards} payment={currentPayment} onPaymentConfirmed={onPaymentConfirmed} closePay={() => setCurrentPayment(null)} />
          </Elements>
          <div
            className="hidden lg:block w-full bg-cover bg-center border-15 border-gray-100 mx-6"
            style={{ backgroundImage: 'url(/image1.jpg)' }} />
          <Delivery
            shippings={shippings}
            event={dealer}
            products={products}
            switchProducts={switchProducts}
            setPickup={pickup => update({ pickup })}
            setAddress={address => update({ address })} />
        </div>
        <div className="w-full">
          {!dealer.events || dealer.events.length === 0 &&
            <div className="text-center my-10 opacity-50 w-full">Aucun événement de planifié</div>
          }
          <div className="flex justify-center w-full">
            {dealer.events.filter(e => e.status !== 'canceled' && !e.date).length > 0 &&
              <Button className="mx-2" onClick={() => setFilter('undefined')} Icon={filter === 'undefined' ? FiCheck : null} variant={filter === 'undefined' ? 'pink' : 'pink-border'}>Événements non planifiés</Button>
            }
            <Button className="mx-2" onClick={() => setFilter('futur')} Icon={filter === 'futur' ? FiCheck : null} variant={filter === 'futur' ? 'pink' : 'pink-border'}>Événements à venir</Button>
            <Button className="mx-2" onClick={() => setFilter('past')} Icon={filter === 'past' ? FiCheck : null} variant={filter === 'past' ? 'pink' : 'pink-border'}>Événements passés</Button>
          </div>
          {dealer.events
            .filter(e => e.status !== 'canceled')
            .filter(e => {
              if (filter === 'undefined') return !e.date
              if (filter === 'futur') return moment(e.date).isAfter(moment())
              if (filter === 'past') return moment(e.date).isBefore(moment())
            })
            .sort((a, b) => moment(a.date).diff(b.date)).map(event => 
              <Event
                key={event.id}
                event={event}
                confirmEvent={() => confirmEvent(event)}
                payAll={(remaining) => payAll(event, remaining)}
                move={() => move(event)}
                cancel={() => cancel(event)}
                shippings={shippings} />
          )}
          <div className="w-full flex justify-center mt-4">
            <Button onClick={newEvent}>Nouvelle location</Button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
